 * {
     padding: 0;
     margin: 0;
     box-sizing: border-box;
     font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
 }



 .why-icons {
     width: 45px !important;
     height: 50px !important;
     display: block;
     margin: 5px auto;
 }

 .why-border {
     border: none;
     /* box-shadow: 1px 1px 5px #000000; */
     /* width: 16rem !important; */
     height: 200px;
     width: 100%;

 }

 .color-heading {
     color:  gray;
 }

 .paragraph {
     font-size: 0.85em;
 }

 .cont-back-color {
     background-color:  gray;
 }

 .circle-1 {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-evenly;
     align-items: center;

 }

 .contents {
     background-color:  gray;
     border-radius: 50%;
     width: 150px;
     height: 150px;
     text-align: center;
     color: white;
 }


 .mission-image {
     height: 300px;
     width: 100%;
     border-radius: 12px;

 }

 .vision-image {
     height: 300px;
     width: 100%;
     border-radius: 12px;
 }

 .mission-content {
     font-size: 22px;
     font-weight: lighter !important;
     color: gray;
     line-height: 1.2;
 }

 .ourteam-content q {
     font-size: 22px;
     font-weight: lighter !important;
     color: gray;
     line-height: 1.2;
 }

 .ourstory-para {
     font-size: 22px !important;
     font-weight: lighter !important;
     color: gray;
     /* line-height: 1.5; */
 }

 .mission-heading {
     padding-top: 30px;
     text-align: center;

 }


 .vision-heading {
     padding-top: 30px;


 }



 .vision-content {
     text-align: justify;

 }

 .happy {
     color: white;

 }




 .aboutus-content {
     text-align: justify;

 }




 .ourteam-content {
     text-align: justify;


 }

 .flip-box-front2 {
     text-align: center;
 }

 .icon-aboutus {
     height: 50px;
     width: 50px;
     color: #FAB702;

 }

 .flip-box-inner2 {
     display: flex;
     flex-direction: column;
     margin: 0px 0;
     gap: 2rem;
 }

 .flip-box-back2 p {
     color: gray;
     text-align: justify;

     font-size: large;
 }




 /* ==============aboutus ourservice============== */


 /* ==================Srart====================== */
 .carousel-img-aboutus-content {
     padding: 10px;
 }

 .carousel-img-aboutus-content-img {
     height: 350px;
     width: 100%;
     border-radius: 12px;
 }

 .main-right-ourservice {
     display: flex;
     justify-content: center;
     flex-direction: column;
     row-gap: 0.7rem;
 }

 .main-right-ourservice-content {
     display: flex;
     column-gap: 1.5rem;
     border-radius: 12px;
     display: flex;
     padding: 5px 0;
     width: 100%;
     justify-content: center;
     padding-left: 1rem;
     transition: all 0.3s ease-in-out;



 }

 .main-right-ourservice-content-inntediv {
     display: flex;
     justify-content: center;

 }

 .main-right-ourservice-content-inntediv-img-div {

     border-radius: 50%;
     background-color: rgba(255, 255, 255, 0.724);
     padding: 25px;

 }

 .main-right-ourservice-content-inntediv-img {
     height: 40px;
     width: 40px;
     transition: all 0.4s ease-in-out;


     color: #FAB702;

 }

 .main-right-ourservice-content:hover {
     background-color: rgba(0, 0, 0, 0.205);
     cursor: pointer;
 }

 .main-right-ourservice-content:hover .main-right-ourservice-content-inntediv-img {
     transform: rotateY(180deg);
     color: #030303;


 }

 .ourstory-main div img {
     height: 300px;
     border-radius: 8px;
 }


 /* ==================END====================== */


 @media screen and (max-width:799px) {

     .why-border {
         border: none;
         /* box-shadow: 1px 1px #ddd; */
         width: auto;
     }

     .mission-image {
         height: 200px;
         width: 100%;
     }

     .vision-image {
         height: 200px;
         width: 100%;
     }

     .contents {
         background-color: green;
         border-radius: 50%;
         width: 140px;
         height: 140px;
         margin: 20px;
         text-align: center;
         color: white;
     }

     .mission-content {
         font-size: 14px;
         text-align: justify;

         font-weight: lighter !important;
         color: gray;

     }

     .ourteam-content q {
         font-size: 14px;
         text-align: justify;

         font-weight: lighter !important;

     }

     .ourstory-para {
         font-size: 14px;
         text-align: justify;
         font-weight: lighter !important;


     }



     .aboutus-setusapart-content {

         display: flex !important;
         flex-direction: column;
         gap: 1rem;
         justify-content: end !important;
         align-items: end !important;
     }

     .ourstory-main {
         flex-direction: column;
     }

     .ourstory-para {
         font-size: small !important;

     }



     .Aboutus-ourservice-content {
         flex-direction: column;
         justify-content: center;
         align-items: center;
     }

     .carousel-img-aboutus-content {
         padding: 0px;
     }

     .main-right-ourservice {
         margin-top: 20px;
     }



     .main-right-ourservice-content-inntediv {
         display: grid;
         place-items: center;
     }

 }