.carousel-indicators {
    display: none !important;
}

.slider-image {
    height: 500px;
    width: 100%;
}

.interior-heading {
    font-size: 50px;
    color: white;
    text-shadow: 2px 2px green;
}

.interior-txt {
    font-size: 35px;
    color: white;
}

.ourstory-image {
    height: 200px;
    width: 100%;
}

.ourstory-content {
    text-align: justify;
    padding-top: 10px;
}

.carousel-text {
    color: white;
}

.ourstory-title {
    color: black;
}

.Welcomepage-para {

    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    text-align: justify;

    color: rgb(153, 153, 153);


    /* font-style: italic; */
}

.title-mainheading {
    /* color:green; */

    font-size: 40px;

}

.viewmore-btn {
    padding: 10px 20px;
    background-color: orange;
    border: 1px solid white;
    border-radius: 10px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    width: 200px;
}

/* .testimonial-content {
    text-align: justify;

}

.content-card {

    text-align: justify;

} */

/* .name {
    font-size: 20px;

} */

.image:hover {
    animation: zoom-in 0.9s ease;
}

.about-us-welcome {
    margin: 20px 0;

    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    /* text-align: center; */
    /* font-size: larger; */
    /* font-weight: 600; */
}



.separator {
    line-height: 0.5;
    text-align: center;
    margin: 20px 0 30px 0;
}

.separator span {
    display: inline-block;
    position: relative;

}

.separator span:before,
.separator span:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(0, 0, 0, 0.285);
    top: 5px;
    width: 300px;
}

.separator span:before {
    right: 100%;
    margin-right: 15px;
}

.separator span:after {
    left: 100%;
    margin-left: 15px;
}

.separator span i {


    color: #FAB702;
    background-color: #FAB702;
    font-size: 6px;
    padding: 0;
    margin: 0;
    /* float: none; */
    border-radius: 50%;
}


.text-light .separator span:before,
.text-light .separator span:after {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.maincard-controller {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.card-title-color {
    color: #FAB702;
}

.img-card-main-type {
    height: 200px;
    border-radius: 5px;
    margin: 15px 0;

}



/* .container-header-we-do {} */

@keyframes zoom-in {

    from {
        transform: scale(2.0);
    }

    to {
        transform: scale(1.0);
    }

}


/* .testimonial-crd .card-img-top {
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial-crd:hover .card-img-top {
    transform: scale(1.1);
  } */



/* .image{
        transition: transform 0.2s ease-in;
  }

  .image:hover{
        transform: scale(1.2);
    } */
.maincard-controller {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


.carousel-header-text {

    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

    position: absolute;
    top: 37%;


    text-shadow:
        1px 1px 0 #0000004b,
        1px 1px 0 #0000004b,
        1px 1px 0 #0000004b,
        1px 1px 0 #0000004b,
        1px 2px 10px #000,
        1px 1px 5px #000
}

@media screen and (max-width:799px) {

    .slider-image {
        height: 300px;
        width: 100%;
    }


    .interior-heading {
        font-size: 25px;
    }

    .interior-txt {
        font-size: 20px;


    }

    .maincard-controller {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin: 10px 0;
        justify-content: space-evenly;
    }

    .carousel-main-div {
        margin: 2px;
        border-radius: 4px;
        overflow: hidden;
    }

    .separator span:before,
    .separator span:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid rgba(0, 0, 0, 0.285);
        top: 5px;
        width: 150px;
    }


}