.contact-img {
    height: 200px;
    width: 100%;
}





.heading {
    position: absolute;
    top: 25%;
    left: 42%;
}

.heading h1 {
    font-size: 40px;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px green;
}


.main-heading {
    font-size: 40px;

}


.form-heading {
    border: 1px solid #f90;
    padding: 10px 20px;
    width: 100%;
    color: black;
    border-radius: 10px;
}

.form-heading input {
    border: none;
    border-color: 2px solid rgb(207, 119, 3);

}

.form-heading>h4 {
    color: white;

}

.form-heading label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: left;
}

.form-heading input {
    padding: 0.5em;
    font-size: 1em;
    margin-bottom: 0.5em;
    border: 1px solid black;
    width: 100%;
    border-radius: 5px;
}


.form-heading textarea {
    width: 100%;
    height: 125px;
    padding: 0.5em;
    font-size: 1.5em;
    resize: none;
}

button {
    padding: 10px 20px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    background-color: #f90;
    ;
    font-size: 1em;
    transition: background-color 0.5s;
}

button:hover {
    background-color: green;
    color: black;
}

.form-img {
    height: 400px;
    width: 100%;
    padding-top: 30px;
}

#background {

    background-color: #f90;
    color: white;
    padding: 20px;
}

.border {
    border: 1px solid black;
    border-radius: 10px;
}

.contact-content {
    font-size: 22px;
}


@media screen and (max-width:799px) {

    .heading {
        position: absolute;
        top: 18%;
        left: 25%;
    }

    .contact-img {
        width: 100%;
        height: 150px;
    }

    .contact-content {
        text-indent: 1em;
        font-size: 16px;
        text-align: justify;
    }

    .form-img {
        height: 300px;
    }
}