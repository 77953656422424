/* .logo {
  width: 100px !important;
  height: 4rem;
}

.navbar-header .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.container-fluid {
  padding: 5px 50px;
}

.navbar-left-content {
  display: flex;
}

.navbar-right-content {
  display: flex;
  padding: 5px 0;
}

.nav-logo-mbl {
  display: none;
}

.headers .nav-link {
  margin: 0px 10px;
  color: green;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 799px) {
  .nav-logo-mbl {
    width: 100px;
    height: 40px;
    display: flex;
  }

  .container-fluid {
    padding: 5px 12px;
  }

  .navbar-toggler {
    display: flex;
    justify-content: flex-end;
    order: -1; 
    margin-left: auto; 
  }
} *

/* .headers{
  background-image: url("../../public//Assets//c1.jpg");
} */



.logo {
  width: 200px !important;
  height: 80px;
}

.navbar-header .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.container-fluid {
  padding: 5px 50px;
}

.navbar-left-content {
  display: flex;
}

.navbar-right-content {
  display: flex;
  padding: 5px 0;
}

.nav-logo-mbl {
  display: none;
}

.headers .nav-link {
  margin: 0px 10px;
  /* background-color: rgb(4, 4, 4); */
  /* color: white; */
  color: black;
  font-size: 14px;


  height: 40px;
  width: 120px;
  border: none;
  border-radius: 10px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}



.nav-link:hover {
  color:rgb(107,55,48) !important;
}


@media screen and (max-width: 799px) {

  .nav-logo-mbl {
    width: 100px;
    height: 40px;
    display: flex;
  }

  .container-fluid {
    padding: 5px 12px;
  }

}