 /* .chat-widget {
   
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
  
  .chat-button {
    background-color: #f90;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .chat-box {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: fixed;
    bottom: 70px;
    right: 20px;

    
  }
  
  .chat-header {
    background-color: #f1f1f1;
    padding: 2px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .chat-content {
    padding: 10px;
    
  }
  
  .chat-form {
    display: flex;
    flex-direction: column;
  }
  
  .chat-form input,
  .chat-form textarea {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-form button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  .arrow-icon{
    font-size: 25px;
   
  }

  .msg-container{
    display: flex;
    justify-content: space-evenly;
  }

  .input-container{
    display: flex;
    justify-content: space-evenly;
   
  }


   */

 .whatsapp-button {
   position: fixed;
   z-index: 99999999999;
   bottom: 10px;
   right: 10px;

   border: none;
   padding: 5px 10px;
   /* Adjust padding as needed */
   font-size: 16px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   /* Center horizontally */
   color: green;
   /* Set the color to green */
   background: transparent;
   border-radius: 10%;
 }

 .whatsapp-button:hover {
   background-color: transparent;
   cursor: pointer;

 }

 .whatsapp-icon {
   margin-right: 0px;
   /* Adjust margin as needed */
   height: 50px;
   width: 50px;
   font-size: 44px;
   /* Increase the icon size */
   position: relative;
   /* Ensure relative positioning for fine adjustments */
   top: 2px;
   /* Adjust vertical position as needed */
 }