.ourprojects-main-div {
    background-color: #FAB702;
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    padding: 25px 0;
}

.ourprojects-main-btn {
    /* background-color: antiquewhite; */
    /* background-color: rgb(106,55,48); */
    border-radius: 8px;
    font-size: larger;
    padding: 10px;
    text-align: center;


}

.ourprojects-main-btn:hover {
    color: #FAB702;
    background-color: black;
}

.responsive-mobile-change {
    text-align: end;

    margin: 0px 25px 15px 0;

}

.responsive-mobile-change button {
    background-color: #FAB702;
    color: #000000;
    font-size: larger;
    transition: all 0.4s ease-in-out;
    font-weight: 300;

}

.responsive-mobile-change button:hover {
    background-color: #000000;
    font-weight: 500;
    color: #FAB702;
}


@media screen and (max-width:799px) {
    .responsive-mobile-change {
        text-align: end;
        margin: -10px 5px 15px 0;
    }
}