 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }

 .Service-img {
   height: 200px;
   width: 100%;
 }

 .service-text {
   position: absolute;
   top: 25%;
   left: 44%;
   font-size: 40px;
   color: white;
   font-weight: bold;
   text-shadow: 2px 2px green;
 }

 /* .service-text h2 {
    font-size: 40px;
    color: white;
} */




 #img-over .images:hover {
   /* transform: scale(1.1);
    overflow: hidden; 
     */
   animation: zoom-in 0.9s ease;

 }

 @keyframes zoom-in {

   from {
     transform: scale(0.8);
   }

   to {
     transform: scale(1.0);
   }

 }


 .choose {
   text-align: center;
   color: green;
 }

 @media screen and (max-width:799px) {
   .Service-img {
     height: 150px;
     width: 100%;
   }

   .service-text {
     position: absolute;
     top: 18%;
     left: 32%;
     font-size: 40px;
     color: white;
   }

 }