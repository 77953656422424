.main-contact-us-page {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.CustomerSays-main-div {
    position: relative;
    top: 0;
}

.customer1 {
    /* z-index: 1; */

    color: white;
}

.customer2 {
    position: absolute;
    top: 0;
    /* z-index: -1; */
}

.divcarousel-inner {
    background-color: rgba(255, 255, 255, 0.693);
    margin: 30px 100px;
    padding: 20px;
    border-radius: 16px;
    transition: all 0.5s ease-in-out;
    font-weight: 500;
    color: black;
}

.divcarousel-inner:hover {
    cursor: default;

}
.owl-stage-outer{
    overflow: visible !important;
}
.tst1{
        position: absolute;
    top: -67px;
}
.social-media-links {
    text-decoration: none;
    color: white;
}

.fas {
    height: 25px;
    width: 25px;
}

.contact-container {
    display: flex;
}

/* In your CSS file */

/* .readMore {
    color: black;
    cursor: pointer;
}

.readLess {
    color: black;
    cursor: pointer;
} */



/* .setion-img {
    width: 100%;
    height: 350px;
     position: relative; 
    } */

/* 
    .contact-bx {
   
        background: #011c3a;
        color: white;
        border-radius: 10px;
        padding: 30px;
        position: relative;
        height: 650px; 
         
    }
    
    .contact-bx img {
        float: left;
        margin-right: 20px;
        background: #fec700;
        padding: 5px;
        width: 50px;
        border-radius:50%;
        
        position: absolute;
        bottom: 95%;
        
    }

    .str{
        color: #fec700;
       
    }
 */

 
 /* .main-content-box{
    height: 400px;
 } */

    .contact-bx {
        background: white;
        color:black;
        border-radius: 10px;
        padding: 30px;
        position: relative;
        height: 300px;     
      }
      .customer-s{
     position: relative;
     z-index: 99;
      }

   .customer1inner .separator span:before,
   .customer1inner .separator span:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid rgb(255 255 255);
    top: 5px;
    width: 300px;
}
      .contact-bx img {
        /* float: left; */
        margin-right: 20px;
        background: #fec700;
        padding: 5px;
        width: 50px;
        border-radius: 50%;
        /* position: absolute;
        bottom: 90%; */
       
        
      }
      
      .str {
        color: #fec700;
      }
      
      /* .tst1{
        hei
      } */

@media screen and (max-width:799px) {
    .divcarousel-inner {
        background-color: rgba(255, 255, 255, 0.212);
        margin: 13px !important;
        padding: 10 !important;

    }

    #separator-home {
        margin: 0 !important;
    }

    .contact-bx{
        height: 100%;
        position: relative;
        top:100px;
    }

}