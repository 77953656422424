.headerServiceMain {
    position: relative;
    overflow: hidden;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.figuretagService {
    position: absolute;
    top: 0;
    z-index: -1;

}

.servicenavbar {

    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    /* color: white; */
}

.gallery-text {
    color: brown;
}


.navgallery-navbar {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    align-items: center;
    border-radius: 7px;
    font-weight: 600;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.247);


}

.navgallery-navbar span {
    padding: 8px 40px;
    border-radius: 4px;


}

.navgallery-navbar span:hover {
    background-color: black !important;
    color: #FAB702 !important;

}

/* ============================= */
.overcontent1 {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 280px;
    width: 280px;

}

.image-container {
    overflow: hidden;
    height: 250px;

    border-radius: 8px;
    border: 1.5px solid gray;

}

.imgcontroll {
    height: 250px;
    width: 100%;
    transition: all 0.3s ease-in-out;

}

.imgcontroll:hover {
    transform: scale(1.1);
}



@media screen and (max-width:799px) {

    .mobile-function-res {
        display: flex;
        flex-direction: column;

    }

    .navgallery-navbar span {
        padding: 8px 5px;
        border-radius: 4px;


    }

    .image-container {
        margin: 5px 0;
    }
}



.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark overlay */
}



.text {
    color: #FAB702;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}