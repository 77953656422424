.contact-info {
    background-color: rgba(0, 0, 0, 0.8);

    border-radius: 8px;
    display: flex;
    flex-direction: column;

    color: white;
}

.contact-item {
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;

    column-gap: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.icon {
    background-color: rgba(0, 0, 0, 0.812);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;

    align-items: center;


}

.info {
    width: 80%;
}


.info h4 {
    margin: 0;

    color: rgb(237, 185, 61);
}

.info p {
    margin: 0;


}

.App1 {
    display: flex;
    justify-content: center;



    background-image: url('./contact1.avif');
    background-size: cover;
    background-position: center;
}

.contact-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px)
}

.contact-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-form h3 {
    margin-bottom: 20px;
    color: #333;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    /* border: 1px solid #ccc; */
    outline-style: none;
    border-style: none;
    border-bottom: 1px solid black;
    /* border-radius: 4px; */
    font-size: 18px;



}

.contact-form button {
    padding: 10px;
    background-color: rgb(237, 185, 61);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color:rgb(237, 185, 61);
}

.contact-form,
.contact-info {
    margin-top: 20px;
    margin-bottom: 20px;
}


@media screen and (max-width:799px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 15px;
    }

    .contact-form,
    .contact-info {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .contact-info {
        margin-top: 10px;
    }

    .contact-form {
        margin-bottom: 10px;
    }

    .info {
        font-size: small;
    }
}