.footer {
    background-color: black;
    color: white !important;
}

.Link_remove_footer {
    text-decoration: none;
    color: rgb(255, 255, 255);
}


.footer_container {
    display: flex;
    justify-content: space-between;


    width: 100%;
}

.footer_about {
    width: 35%;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.social_icons {
    height: 30px;
    width: 30px;
    position: relative;
    left: -35px;
    transition: all 0.5s ease;
}

.social_icons:hover {
    /* scale: 1.4; */

    animation: zoom-in-out 1s 0.5s infinite;

}

@keyframes zoom-in-out {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);

    }

    100% {
        transform: scale(1);
    }

}



#twitter {
    color: rgb(255, 255, 255);
}

#facebook {
    color: blue;
}

#insta {
    color: #C1275D;
}

#youtube {
    color: yellow;
}

.footer_ol {
    display: flex;
    list-style: none;
    justify-content: space-evenly;

}

.footer_quick {
    margin: 10px;
    padding: 10px;
    line-height: 24px;
}

.footer_quick_ol {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.quick_listss {
    position: relative;
    left: -30px;
}

.footer_more {
    margin: 10px;
    padding: 10px;
    line-height: 34px;
}

.more_ul {
    list-style: none;
    text-align: start;
}

.more_ul a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.footer_contact {

    padding: 10px;
    margin: 10px;
}

@media screen and (max-width:799px) {
    .footer_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .footer_about {
        width: 100%;
        margin: 0;

        /*  */
    }

    .footer_quick,
    .footer_more {
        width: 100%;

        margin: 0;



        /* line-height: 25px; */
    }

    .footer_contact {
        width: 100%;
        margin: 0;



        /* margin: 1px; */
        /* line-height: 10px; */
    }
}