.main-div-ourprocess {
    position: relative;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.circle {
    height: 180px;
    display: grid;
    place-items: center;
    width: 180px;
    border-radius: 50%;
    color: black;
    position: relative;
    bottom: 20px;
    font-weight: 650;

    transition: all .5s ease-in-out;

    background-color: #D49F0E;


}

.circle:hover {
    color: white;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.483);
}

.circle:active {
    background-color: transparent;
}

.circle:active::after {
    content: " ";
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.392);
    position: absolute;
    /* float: bottom; */
    top: 178px;
}

.margin-hr-ourprocess {
    margin-top: 49px;
    border: 1px solid rgba(255, 255, 255, 0.863);
}

.img-background-front {
    z-index: 1;
    
}

.img-background {
    position: absolute;
    top: 0;
    z-index: -1;
    /* height: 490px;
    width: 100%; */
    overflow: hidden;
    /* height: 500px; */
}

.main1div {
    display: flex;
    justify-content: space-evenly;
    color: rgb(255, 255, 255);
}

.flip-box-inner2 {
    display: flex;
    gap: 4px !important;
}

@media screen and (max-width:799px) {

    .img-background {
        position: absolute;
        top: 0;
        z-index: -1;
        height: 1000px;
    }

    .aboutus-setusapart-content {
        gap: 0 !important;
    }

    .margin-hr-ourprocess {
        margin-top: 9px;
        border: 1px solid rgba(255, 255, 255, 0.863);

    }

    .flip-box-inner2 {
        display: flex;
        gap: 0 !important;
    }

    .main1div {
        display: flex;
        justify-content: space-between;


        align-items: center;
        flex-direction: column;
        color: rgb(255, 255, 255);
    }


    .circle {
        margin: 3px 0;
        height: 130px;
        width: 130px;
    }

    .circle:active::after {
        content: " ";
        height: 0px;
        border: 1px solid rgba(255, 255, 255, 0.392);
        position: absolute;
        /* float: bottom; */

    }
}