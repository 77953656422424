.headerServiceMain {
    position: relative;
    overflow: hidden;

}

.Service-img-main-card-controll {
    height: 180px;
    border-radius: 2px;
}

.figuretagService {
    position: absolute;
    top: 0;
    z-index: -1;

}

.knowmore{
    background-color:  rgb(237, 185, 61);
    color: white;
    border-radius: 10px;
}

.knowmore:hover{
    background-color:  grey;
    
}

/* .figuretagService img {} */

.services-common-inner-content {
    padding-left: 10px;
}

.servicenavbar {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.223);
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    color: white;
}

.serviceMainContent {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.card-service-controll {
    margin: 0;
    width: 20rem
}


/* ================start=================== */
/* Common css for service-contents  */
/* =================================== */




.common-all-div-service-content {
    position: relative;
    top: 0;
    padding: 0 5px 0 0;

}

.stickycontroll1 {
    position: sticky;
    position: -webkit-sticky;
    top: 180px;
    width: 100%;

}

.stickycontroll {
    position: sticky;
    position: -webkit-sticky;
    top: 80px;
    width: 100%;

}

.ul-main {

    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;

}

.listmain {
    padding: 15px;
    text-align: left;
    margin: 1px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    font-size: 30px;
    background-color: gray;
    color: rgb(34, 34, 34);

}

.listmain:hover {
    background-color: #FAB702;
    color: black;
}

.bgcolor {
    background-color: #FAB702;
    color: black;
}

.heading-service-common-content-center {
    font-size: 28px;

    font-weight: 600;
    margin: 0 !important;


}

.paragraph-service-common-content {
    font-size: 18px;
    text-indent: 2em;

    text-align: justify;
    line-height: 1.1;
    margin: 0 !important;
}

.image-common-div-service-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.image-common-div-service-content img {
    margin-top: 15px;
    margin-bottom: 25px;


    height: 230px;
    width: 350px;

    border-radius: 8px;

}

.filper-main-content-main-div {

    gap: 10px 0px;
    margin: 15px 0 0 0 !important;

}

.flip-box {
    background-color: transparent;

    width: 150px;
    height: 50px;

    perspective: 800px;
}

.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: grid;
    place-items: center;
    border-radius: 8px;
}

.flip-box-front {

    background-color: #FAB702;
    color: black;
}

.flip-box-back {
    color: #FAB702;
    background-color: black;
    transform: rotateY(180deg);
}

.flip-box-back p {


    font-size: 13px;

}

/* =================end================== */
/* Common css for service-contents  */
/* =================================== */

@media screen and (max-width:799px) {

    .serviceMainContent {
        width: 100%;
        align-items: center;
        flex-direction: column;

        justify-content: space-evenly;
        padding: 20px 0;
    }

    .card-service-controll {
        margin: 5px 0px
    }

    .image-common-div-service-content img {
        width: 350px !important;
        height: 250px !important;
        margin: 15px 10px 0 10px !important;
        padding: 5px;

    }

    .the-main-container-content {
        flex-direction: column;
    }




    .filper-main-content-main-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px 0px;
        margin: 15px 0 !important;



    }

    .flip-box {
        background-color: transparent;

        width: 150px;
        height: 50px;

        perspective: 800px;
    }

    .flip-box-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;

    }

    .flip-box:hover .flip-box-inner {
        transform: rotateY(180deg);
    }

    .flip-box-front,
    .flip-box-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: grid;
        place-items: center;
        border-radius: 8px;
        font-size: 14px;


    }

    .flip-box-front p,
    .flip-box-back p {
        display: flex;
        justify-content: center;

        margin-bottom: 0 !important;


    }


    .flip-box-front {

        background-color: #FAB702;
        color: black;
    }

    .flip-box-back {
        color: #FAB702;
        background-color: black;
        transform: rotateY(180deg);
    }


}